<template>
  <v-container>
     <div class="divContrato">
       <v-data-table 
       v-if="modalTable"
         :items="contratos"
         :headers="headers"
         :items-per-page="15"
         @click:row="(item) => mostrarContrato(item)">
       >
       </v-data-table>

       <button class="button" style="margin-bottom: 20px;" @click="voltar" v-if="modalExibirContrato">Voltar</button>
       <button v-if="modalTable" class="button" style="margin-bottom: 20px;" @click="voltarAtualizarCliente">Voltar</button>
       <button v-if="modalExibirContrato" class="button" style="margin-bottom: 20px; margin-left: 20px;" @click="imprimir">Imprimir</button>
       <button v-if="modalExibirContrato" class="button" style="margin-bottom: 20px; margin-left: 20px; width: 300px;" @click="() => {
        this.modalEnviarEmail = true 
        this.email = null}">Enviar contrato via e-mail</button>
       <div id="contrato" ref="exportPdf" v-html="contratoExibir" v-if="modalExibirContrato" style="margin: 50px"></div>
  
      <v-dialog v-model="modalEnviarEmail" width="400px" max-width="50vw" persistent>
        <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
            <h4>Enviar contrato via e-mail</h4>
            <input type="text" class="inputEmail" placeholder="E-mail" v-model="email"/>
            <p v-if="mensagemEmailEnviado">E-email enviado com sucesso</p>
            <div style="display: flex; justify-content: space-around; width: 100%;">
              <button class="button" @click="() => {
                this.modalEnviarEmail = false
                this.mensagemEmailEnviado = false;
              }">Fechar</button>
              <button class="button" @click="enviarEmail">{{  botaoEnviarEmail  }}</button>
            </div>
        </div>
      </v-dialog>

     </div>
   </v-container>
 </template>
 
 <script>
   import termo_de_adesaoService from '../../services/termo_de_adesao.service';

   export default {

    data() {
       return {
         headers: [
         { text: 'Titulo', value: 'titulo'},
       ],
       contratos: [],
       alerta: false,
       mensagem: null,
       contratoDeletar: null,
       modalExibirContrato: false,
       modalTable: true,
       contratoExibir: null,
       paginaLGPD: 1,
       termoLGPDSrc: null,
       modalEnviarEmail: false,
       email: null,
       idcontrato: null,
       botaoEnviarEmail: 'Enviar',
       mensagemEmailEnviado: false,
       }
     },
     methods: {

      imprimir() {
        var contentOfDiv = document.getElementById("contrato").innerHTML;
            var newWin = window.open(null, '', 'height=650, width=650');
            newWin.document.write('');
            newWin.document.write('<title>Economy Software</title>');
            newWin.document.write(' <h1>Imprimir: <br>');
            newWin.document.write(contentOfDiv);
            newWin.document.write('');
            newWin.document.close();
            newWin.print();
      },

      mostrarContrato(item) {
        console.log(item)
        this.idcontrato = item.idcontrato
        this.contratoExibir = item.contrato
        this.modalTable = false
        this.modalExibirContrato = true
      },


      voltar() {
        this.contratoExibir = null
        this.modalTable = true
        this.modalExibirContrato = false
      },

      voltarAtualizarCliente() {
        const id = localStorage.getItem('idCliente')
        this.$router.push(`/lista/cliente/${id}`)
      },

       async buscarContratos() {
         try {
           const result = await termo_de_adesaoService.buscarContratoDoCliente({ idcliente: localStorage.getItem('idCliente') });
           this.contratos = result.data;
         } catch (error) {
           console.log(error.response);
         }
       },

       async enviarEmail() {
        this.botaoEnviarEmail = 'Aguarde...'
        try {
          if(!this.email) {
            return;
          }
          const body = {
            idcliente: localStorage.getItem('idCliente'),
            idcontrato: this.idcontrato,
            email: this.email
          }
          const result = await termo_de_adesaoService.salvar(body)
          console.log(result)
          this.botaoEnviarEmail = 'Enviar';
          this.mensagemEmailEnviado = true;
        } catch (error) {
          this.modalEnviarEmail = false;
          console.log(error);
        }
       }
     },
     async mounted() {
      await this.buscarContratos()
     }
   }
 </script>
 
 <style lang="scss" scoped>

 .divContrato {
   margin: 0 auto 5em auto;
   width: 900px;
   max-width: 80vw;
   box-shadow:  4px 4px #d9d9d9;
   padding: 3em 1em 1em 1em;
   border-radius: 30px;
   background-color: #f9f9f2;
 }
 
 .inputName {
   background-color: white;
   border: 1px solid rgb(122, 122, 122);
   margin: 0 0 10px;
   border-radius: 5px;
   padding: 8px;
   width: 100%;
 }
 
 .button {
   background-color: #3A78F2;
   color: white;
   padding: 5px;
   width: 100px;
   border-radius: 5px;
   margin: 10px 0 0 0;
 }
 
 .buttonEditar {
   background-color: #3A78F2;
   color: white;
   padding: 5px;
   width: 100px;
   border-radius: 5px;
 }
 
 .header{
   display: flex;
   padding: 1em;
   margin: 0 auto;
   width: 80vw;
   max-width: 900px;
 
   >h3{
     letter-spacing: 0.02em;
   }
   margin: 1em auto;
   background: rgba(215,211,222,0.5);
   height: fit-content;
   border-radius: 10px;
   >span{
     display: flex;
     flex-direction: column;
     align-items: flex-start;
   }
 }
 
 .btn{
       padding: .75em 1em;
       line-height: 1.5;
       color: white;
       font-weight: bold;
       letter-spacing: 0.02em;
       background: #1377f2;
       border-radius: 10px;
       text-transform: capitalize;
   }

  .inputEmail {
    background: white;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    margin: 15px 0 10px 0;
  }
 </style>